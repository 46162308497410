import React, { useState } from 'react';
import SecPageCompoOne from './FivePageCompoOne';
import SecPageCompoTwo from './FivePageCompoTwo';
import SecPageCompoThree from './FivePageCompoThree';
import SecPageCompoFour from './FivePageCompoFour';
import { createContext } from 'react';
import SecPageCompoFive from './FivePageCompoFive';
import SecPageCompoSix from './FivePageCompoSix';
import Form from '../Form/index';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function index() {
  // Open targated component hook
  const [openComponent, setopenComponent] = useState<number>(1);

  // states to POST data
  const [goal, setGoal] = useState<string>('');
  const [product, setProduct] = useState<string>('');
  const [creditScore, setCreditScore] = useState<string>('');
  // const [mortageBal, setMortageBal] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [secondname, setSecondname] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [place, setPlace] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setzipCode] = useState<string>('');
  const [popup, setPopup] = useState<boolean>(false);

  // const [homeWorthval, setHomeWorthVal] = useState<any>('');

  // useReducers**********

  const [homeWorthval, setHomeWorthVal] = React.useReducer((state: string, newValue: string) => {
    const [formattedWholeValue, decimalValue = ''] = newValue.split('.');
    const signifantValue = formattedWholeValue.replace(/,/g, '');
    const floatValue = parseFloat(signifantValue + '.' + decimalValue.slice(0, 2));
    if (isNaN(floatValue) === false) {
      let n = new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(floatValue);
      if (newValue.includes('.') && !n.includes('.')) {
        return n + '.';
      }
      return n;
    }
    return '';
  }, '');
  // ***********************
  const [mortageBal, setMortageBal] = React.useReducer((state: string, newValue: string) => {
    const [formattedWholeValue, decimalValue = ''] = newValue.split('.');
    const signifantValue = formattedWholeValue.replace(/,/g, '');
    const floatValue = parseFloat(signifantValue + '.' + decimalValue.slice(0, 2));
    if (isNaN(floatValue) === false) {
      let n = new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(floatValue);
      if (newValue.includes('.') && !n.includes('.')) {
        return n + '.';
      }
      return n;
    }
    return '';
  }, '');
  // ***********************

  // imp_to_us -> goal
  // mortgage_balance_owing ->Mortgage balance
  // current_credit_score -> credit score

  // console.log(product);

  const formatCurrency = (homeWorthval: string) => {
    return (
      '$' +
      parseFloat(homeWorthval)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    );
  };

  // let formattedCostValue = formatCurrency(cost)
  // let formattedHomeValue = formatCurrency(homeWorthval);
  // let formattedMortageVal = formatCurrency(mortageBal);

  // *********
  function phoneFormat(n: any) {
    //returns (###) ###-####
    let input = n.slice(2);
    // let input = n
    // input = input.replace(/\D/g, '')
    // console.log(input)
    var size = input.length;
    if (size > 0) {
      input = '(' + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4, 11);
    }
    if (size > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9);
    }
    return input;
    // console.log(input)
  }
  // *********

  // console.log(place)
  // console.log(city)
  // console.log(state)
  // console.log(zipCode)
  // console.log(contact);

  // let formattedCostValue = formatCurrency(cost)
  // let formattedHomeWorthValue = formatCurrency(homeWorthval)

  const handleLandingPageData = async () => {
    let fullname = `${name} ${secondname}`;

    console.log('******Address*******');
    console.log('******Place*******');
    console.log(place);
    // console.log(theAdd)
    console.log('******City*******');
    console.log(city);
    console.log('******State*******');
    console.log(state);
    console.log('******ZipCode*******');
    console.log(zipCode);

    // *************Build formData object.***************

    // --------------------------------------
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'You’re all set!',
      text: 'Our team is already Working on your report.',

      customClass: {
        popup: 'page5_popup'
      },
      inputAttributes: {
        id: 'page5_popup'
      }
    }).then(() => {
      setPopup(true);
    });
    // --------------------------------------
    let formData = new FormData();
    formData.append('place', place);
    formData.append('imp_to_us', goal);
    formData.append('mortgage_balance_owing', `$${mortageBal}`);
    formData.append('current_credit_score', creditScore);
    // formData.append('product_id', '1');
    formData.append('name', fullname);
    formData.append('email', email);
    formData.append('phone', phoneFormat(contact));
    formData.append('product', product);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zipCode', zipCode);
    // formData.append('money_need', `234`);
    formData.append('home_value', `$${homeWorthval}`);
    formData.append('lead_source', '36');

    // // *********************API starts**********************

    // https://corsproxyapi.herokuapp.com/

    try {
      const data = await axios({
        method: 'post',
        // url: 'https://dev.nu2morrow.com/crm/apis/lead/add_landingpage_dev',
        url: 'https://nu2morrow.com/crm/apis/lead/add_landingpage',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // console.log(data);
    } catch (err) {
      console.log(err);
    }
    console.log(formData);

    // *************************API ends*************************

    // console.log(`$${home_value}`)
    // console.log(`$ ${cost}`)

    setProduct('');
    // setPropertyType('')
    // setCost('')
    setName('');
    // setContact('');
    setName('');
    setSecondname('');
    setCreditScore('');
    setGoal('');
    // setContact('');
    // setEmail('');
    // setinpAddress('')
    setPlace('');
    setCity('');
    setState('');
    setzipCode('');

    console.log(place);

    // sethomeCost('')
  };

  if (popup) {
    console.log('popup: ', popup);
    window.location.href = 'https://nuborrow.com/'; //Will take you to Nuborrow Web App.
  }

  return (
    <div id="view" className="">
      {/* <AppContext.Provider value={{openComponent, setopenComponent}}> */}
      <Helmet>
        <script>
          {`
            dataLayer.push({
              'event':'formsubmitted',
              'enhanced_conversion_data': {
                "email": ${email},
                "phone_number": ${contact}
              }
            })
          `}
        </script>
      </Helmet>
      {openComponent === 1 ? (
        <SecPageCompoOne
          setProduct={setProduct}
          product={product}
          setopenComponent={setopenComponent}
        />
      ) : openComponent === 2 ? (
        <SecPageCompoTwo setGoal={setGoal} setopenComponent={setopenComponent} />
      ) : openComponent === 3 ? (
        <SecPageCompoThree
          formatCurrency={formatCurrency}
          setHomeWorthVal={setHomeWorthVal}
          homeWorthval={homeWorthval}
          // formattedHomeValue={formattedHomeValue}
          setopenComponent={setopenComponent}
        />
      ) : openComponent === 4 ? (
        <SecPageCompoFour
          mortageBal={mortageBal}
          setMortageBal={setMortageBal}
          setopenComponent={setopenComponent}
        />
      ) : openComponent === 5 ? (
        <SecPageCompoFive setCreditScore={setCreditScore} setopenComponent={setopenComponent} />
      ) : (
        // <SecPageCompoSix
        //   setopenComponent={setopenComponent}
        //   setState={setState}
        //   setPlace={setPlace}
        //   setCity={setCity}
        //   setzipCode={setzipCode}
        //   place={place}
        // />
        <Form
          setEmail={setEmail}
          email={email}
          setopenComponent={setopenComponent}
          handleLandingPageData={handleLandingPageData}
          setName={setName}
          name={name}
          setContact={setContact}
          contact={contact}
          setState={setState}
          setPlace={setPlace}
          setCity={setCity}
          setzipCode={setzipCode}
          place={place}
          backId={5}
          state={state}
          buttonId={'page5_btn_submit_report'}
        />
      )}
    </div>
  );
}

export default index;
